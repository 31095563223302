export type RestrictionType = 'unrestricted' | 'restricted-all-users' | 'restricted-selected-users' | ''
export class PaymentTypeDTO {

  public id = 0
  public type = ''
  public title = ''
  public description = ''
  public status = false
  public message = ''
  public amountMax = 0
  public amountMin = 0
  public feeType = 'none'
  public feeAmount = 0
  public orgaId = 0
  public archiveId = 0
  public restriction: RestrictionType = 'unrestricted'
  public isPaymentActiveForFirstTime = false
  

  // RESTRICTION
  static UNRESTRICTED = 'unrestricted'
  static RESTRICTED_ALL_USERS = 'restricted-all-users'
  static RESTRICTED_SELECTED_USERS = 'restricted-selected-users'

  constructor(init?: Partial<PaymentTypeDTO>) {

    if (init === undefined) return

    Object.assign(this, init)

    // this.setIntToDecimal()
  }

  // public setIntToDecimal() {
  //   this.amountMax = this.amountMax && this.amountMax > 0 ? this.amountMax / 100 : 0

  //   this.amountMin = this.amountMin && this.amountMin > 0 ? this.amountMin / 100 : 0

  //   this.feeAmount = this.feeAmount && this.feeAmount > 0 ? this.feeAmount / 100 : 0
  // }
  
}
